import React, { useState } from 'react'
import { useForm } from '@formspree/react'
import { Button, Form as F } from 'react-bootstrap'
import cn from 'classnames'
import { form, succeeded, form_title, form_descr, form_field, form_btn, close } from './style.module.scss'

export const Form = ({ popup, handleClose }) => {
  const [state, handleSubmit] = useForm('xzbyknzd')
  const [validated, setValidated] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
    } else {
      handleSubmit(event).then((res) => {
        setValidated(false)
        event.target.reset()
      })
    }

    setValidated(true)
  }
  return (
    <F className={form} noValidate validated={validated} onSubmit={onSubmit}>
      <p className={cn(form_title, { [succeeded]: state.succeeded })}>
        {state.succeeded ? 'Thank you!' : 'Sign up for Updates'}
      </p>
      <p className={form_descr}>
        {state.succeeded
          ? 'You’ve successfully subscribed.'
          : 'Sign up with your email address to receive news and updates.'}
      </p>
      {!state.succeeded && (
        <F.Control
          className={form_field}
          type="email"
          placeholder="Enter your mail"
          name="email"
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          required
        />
      )}
      {popup && state.succeeded ? (
        <Button className={cn(form_btn, close)} variant="hollow" onClick={handleClose}>
          Close
        </Button>
      ) : (
        <Button disabled={state.submitting || state.succeeded} className={form_btn} type="submit">
          Subscibe{state.succeeded && 'd'}
        </Button>
      )}
    </F>
  )
}
